import * as yup from 'yup';
import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { clsx } from 'clsx';
import axios from 'axios';
import {
  useForm,
  Controller,
  useFieldArray,
  FormProvider,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  inviteUserToAccount,
  getSubscriptionStatus,
  useGetLearningResourceSubscription
} from 'actions/learningResources';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { Select, SelectOption } from 'components/Organization/Select';
import { roleToText } from 'helpers/role';
import { toastSuccess } from 'components/Organization/Toast';
import EmailAutocomplete from 'components/Organization/EmailAutocomplete';
import LeaveModal from 'components/Organization/LeaveModal';
import RouteHelpers from 'helpers/routes';

const schema = yup.object({
  members: yup.array().of(
    yup.object({
      email: yup
        .string()
        .email(i18n.ft(messages.errors.email))
        .required(i18n.ft(messages.errors.required))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, i18n.ft(messages.errors.email)),
      role_id: yup.number().nullable()
    })
  )
});

function parseError(error: any) {
  return error?.response?.data ?? [{}];
}

type RoleSelectionOption = SelectOption & { name: string };

interface UserFormProps {
  index: number;
  roles: RoleSelectionOption[];
  remove?: () => void;
}

interface Member {
  email?: string | null;
  role_id?: number | null;
}

function UserForm({ index, roles, remove }: UserFormProps) {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    trigger
  } = useFormContext();
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;
  const { data: subscription } = useGetLearningResourceSubscription(accountId);
  const [subscriptionError, setSubscriptionError] = useState<string | null>(
    null
  );

  function checkSubscription(email: string) {
    if (!email) {
      return;
    }

    getSubscriptionStatus({
      email: email,
      subscription_id: subscription?.[0]?.id ?? 0
    })
      .then(response => {
        const { invite_status, name, role } = response.data;

        const statusActions = {
          invited: {
            error: i18n.ft(messages.errors.invited, { name, role }),
            setError: true
          },
          accepted: {
            error: i18n.ft(messages.errors.subscribed, { name, role }),
            setError: true
          },
          no_invitation: {
            error: i18n.ft(messages.errors.noInvitation, { name, role }),
            setError: true
          }
        };

        if (statusActions[invite_status]) {
          setSubscriptionError(statusActions[invite_status].error);
          if (statusActions[invite_status].setError) {
            setError(`members.${index}.email`, {
              type: 'manual'
            });
          }
        } else {
          setSubscriptionError(null);
          clearErrors(`members.${index}.email`);
        }
      })
      .catch(error => {
        axios.isAxiosError(error) && error.response?.status === 404
          ? setSubscriptionError(null)
          : setSubscriptionError(i18n.ft(messages.errors.error));
      });
  }

  function setUserRole(roleId: number) {
    setValue(`members.${index}.role_id`, roleId);
  }

  function handleChange(value: string, field: any) {
    field.onChange(value);
    setSubscriptionError(null);
    clearErrors(`members.${index}.email`);
  }

  function handleFocus() {
    setSubscriptionError(null);
    clearErrors(`members.${index}.email`);
  }

  async function handleBlur(field: any) {
    if (field.value) {
      const isValid = await trigger(`members.${index}.email`);
      if (!isValid) {
        setError(`members.${index}.email`, {
          type: 'manual',
          message: i18n.ft(messages.errors.email)
        });
      } else {
        checkSubscription(field.value);
      }
    }
  }

  function handleSelect(customer: any) {
    setValue(`members.${index}.email`, customer.email);
    checkSubscription(customer.email);
  }

  return (
    <section>
      {subscriptionError && (
        <div className="grid grid-cols-2 font-sans font-semibold text-base text-[#3C3F42] p-4 bg-[#EED3D8] border-x border-t border-[#AC213A] rounded-t-2xl items-center gap-2">
          <div>
            <i className="fa-solid fa-circle-xmark text-[#AC213A] mr-2" />
            <span>{subscriptionError}</span>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                setValue(`members.${index}.email`, '');
                setValue(`members.${index}.role_id`, null);
                setSubscriptionError(null);
              }}
              className="text-[#3C3F42] underline text-base font-sans font-bold"
            >
              {i18n.ft(messages.clearForm)}
            </button>
          </div>
        </div>
      )}
      <div
        className={clsx(
          'bg-white p-6 rounded-2xl',
          subscriptionError &&
            'rounded-t-none border-b border-x border-[#AC213A]'
        )}
      >
        {remove ? (
          <div className="flex justify-end">
            <button
              type="button"
              onClick={remove}
              className="text-action text-sm font-sans font-semibold"
            >
              {i18n.ft(messages.deleteForm)}
              <span className="fa fa-trash-can fa-solid pl-2"></span>
            </button>
          </div>
        ) : (
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                setValue(`members.0.email`, '');
                setValue(`members.0.role_id`, null);
                setSubscriptionError(null);
              }}
              className="text-action text-sm font-sans font-semibold"
            >
              {i18n.ft(messages.clearForm)}
              <span className="fa fa-close fa-solid pl-2"></span>
            </button>
          </div>
        )}
        <div className="grid gap-6 grid-cols-2">
          <Controller
            name={`members.${index}.email`}
            control={control}
            rules={{
              required: i18n.ft(messages.errors.required),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: i18n.ft(messages.errors.email)
              }
            }}
            render={({ field }) => (
              <EmailAutocomplete
                value={field.value}
                onChange={value => handleChange(value, field)}
                onFocus={handleFocus}
                onBlur={() => handleBlur(field)}
                onSelect={handleSelect}
              />
            )}
          />
          <Controller
            name={`members.${index}.role_id`}
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                label={i18n.ft(messages.role)}
                placeholder={i18n.ft(messages.rolePlaceholder)}
                onChange={setUserRole}
                options={roles}
              />
            )}
          />
        </div>
        {Array.isArray(errors.members) && errors.members[index]?.email && (
          <p className="text-red-500 text-sm mt-1 font-semibold">
            {errors.members[index].email.message}
          </p>
        )}
      </div>
    </section>
  );
}

interface InviteUsersProps {
  /** Current account id. */
  accountId: number;

  /** List of roles formatted for select component. */
  roles: RoleSelectionOption[];
}

function InviteUsers({ accountId, roles }: InviteUsersProps) {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { members: [{}] }
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members'
  });
  const members = useWatch({ name: 'members', control });
  const { id } = useParams();

  const { isPending, mutate: addUser } = useMutation({
    mutationFn: inviteUserToAccount,
    onSuccess: ({ data }) => {
      navigate(
        RouteHelpers.getPath('organization-subscriptions-learning-resources', {
          id: id
        })
      );
      toastSuccess({
        message: i18n.ft(messages.userInvited, { count: data.length })
      });
    },
    onError: error => {
      const errors = parseError(error);

      errors.forEach((userError: any, index: number) => {
        if (userError) {
          if (userError.email) {
            setError(`members.${index}.email`, { message: userError.email });
          }

          if (userError.account_user) {
            setError(`root.${index}`, { message: userError.account_user });
          }
        }
      });
    }
  });

  const handleFormSubmit = (data: any) => {
    if (isPending) {
      return;
    }
    //Update with the right API call once it's available - LRN-1795
    console.log(data);
  };

  const areUsersReadyToInvite = () => {
    const hasErrors =
      Array.isArray(errors.members) && errors.members.some(error => error);
    return (
      !hasErrors &&
      (members ?? []).every(member => member.email && member.role_id)
    );
  };

  const handleOpenModal = () => {
    const isMemberEmpty = (member: Member) =>
      member.role_id == null && !member.email;

    if ((members ?? []).every(isMemberEmpty)) {
      handleLeave();
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLeave = () => {
    reset();
    setModalOpen(false);
    navigate(
      RouteHelpers.getPath('organization-subscriptions-learning-resources', {
        id: id
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
        <div className="pt-6 md:pb-4 lg:pb-6">
          <button
            type="button"
            onClick={handleOpenModal}
            className="text-action text-sm font-sans font-semibold mt-4"
          >
            <i className="fa-solid fa-arrow-left no-underline" />
            <span className="ml-2">{i18n.ft(messages.back)}</span>
          </button>
        </div>

        <LeaveModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onLeave={handleLeave}
        />

        <div className="grid grid-cols-2">
          <h2 className="font-sans font-bold text-black text-3xl py-4">
            {i18n.ft(messages.new)}
          </h2>
          <div className="py-4 flex justify-end">
            <PrimaryButton type="button">
              <span className="inline-flex mr-2 align-bottom">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM7 9L8.41 10.41L11 7.83V16H13V7.83L15.59 10.41L17 9L12 4L7 9Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span>{i18n.ft(messages.upload)}</span>
            </PrimaryButton>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4"
        >
          {fields.map((item, index) => (
            <UserForm
              key={item.id}
              index={index}
              roles={roles}
              remove={index !== 0 ? () => remove(index) : undefined}
            />
          ))}

          <div className="flex justify-between">
            <button
              className="cursor-pointer text-action font-sans text-sm font-semibold py-4"
              onClick={() =>
                append({
                  email: '',
                  role_id: null
                })
              }
            >
              <span className="fa fa-plus fa-solid pr-2"></span>
              {i18n.ft(messages.addAnotherUser)}
            </button>
            <div className="py-4 flex justify-end">
              <PrimaryButton
                type="submit"
                disabled={!areUsersReadyToInvite()}
                className="disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-navy-200 disabled:pointer-events-none"
              >
                {i18n.ft(messages.inviteUsers)}
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

function InviteUsersController() {
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;

  const data = [
    {
      id: 1,
      name: 'lr_admin'
    },
    {
      id: 2,
      name: 'lr_educator'
    },
    {
      id: 3,
      name: 'lr_coach'
    }
  ];

  const roles = data.map(role => ({
    value: role.id,
    text: roleToText(role.name),
    name: role.name
  }));

  return <InviteUsers accountId={accountId} roles={roles} />;
}

export default InviteUsersController;
