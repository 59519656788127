const PATH = 'fe.pages.organization.learning_resources';

const messages = {
  invite: {
    key: `${PATH}.invite`,
    defaultValue: 'Invite Users'
  },
  learningResourcesUsers: {
    key: `${PATH}.learning_resources_users`,
    defaultValue: 'Learning Resources Users'
  },
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  inviteStatus: {
    key: `${PATH}.invite_status`,
    defaultValue: 'Invite Status'
  },
  filterStatus: {
    key: `${PATH}.filter_status`,
    defaultValue: 'Filter Status'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: 'Invited On'
  },
  usersCount: {
    key: `${PATH}.users_count`,
    defaultValue: 'Showing {{initial}}-{{end}} of {{total}}'
  },
  totalPages: {
    key: `${PATH}.total_pages`,
    defaultValue: 'Total: {{count}} pages'
  },
  filterRoles: {
    key: `${PATH}.filter_roles`,
    defaultValue: 'Filter roles'
  },
  clearRoles: {
    key: `${PATH}.clear_roles`,
    defaultValue: 'Clear selected roles'
  },
  clearStatus: {
    key: `${PATH}.clear_status`,
    defaultValue: 'Clear selected status'
  },
  unsubscribedUsers: {
    key: `${PATH}.unsubscribed_users`,
    defaultValue: 'View Unsubscribed Users'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach'
  },
  invitedStatus: {
    key: `${PATH}.invited_status`,
    defaultValue: 'Invited'
  },
  notInvitedStatus: {
    key: `${PATH}.no_invitation_status`,
    defaultValue: 'No Invitation'
  },
  acceptedStatus: {
    key: `${PATH}.accepted_status`,
    defaultValue: 'Accepted'
  },
  view: {
    key: `${PATH}.view`,
    defaultValue: 'View'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  assignCoach: {
    key: `${PATH}.assign_coach`,
    defaultValue: 'Assign to Coach'
  },
  resendInvite: {
    key: `${PATH}.resend_invite`,
    defaultValue: 'Resend Invite ({{count}})'
  },
  unsubscribe: {
    key: `${PATH}.unsubscribe`,
    defaultValue: 'Unsubscribe Selected ({{count}})'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue:
      '{{educators}} educator(s) have been assigned to the coach group'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Error assigning users to coach. Please try again later.'
  }
};

export default messages;
