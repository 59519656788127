import i18n from 'helpers/i18n';
import messages from './messages';
import * as R from 'ramda';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { DropdownItemProps } from 'semantic-ui-react';
import {
  GetAveragesByClassroomRes,
  getAveragesByClassroom,
  getReportConfigurations
} from 'actions/envScales/reports';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';
import { AssessmentTemplate } from 'types/api/envScales/AssessmentTemplate';
import { classAgeLevels, class2EditionAgeLevels } from 'constants/ageLevels';
import { UserReportConfiguration } from 'types/api/envScales/UserReportConfiguration';

import {
  getAverages,
  GetAveragesRes,
  getAveragesByNode,
  GetAveragesByNodeRes
} from 'actions/envScales/reports';

export function getDomainColor(domain: string) {
  switch (domain) {
    case 'emotional_support':
    case 'emotional_and_behavioral_support':
    case 'responsive_caregiving':
      return '#fcc597';
    case 'classroom_organization':
    case 'engaged_support_for_learning':
      return '#ffa9c6';
    case 'instructional_support':
      return '#f0b2ff';
    default:
      return '#f4f8fa';
  }
}

export function getDomainBgColor(domain: string) {
  switch (domain) {
    case 'emotional_support':
    case 'emotional_and_behavioral_support':
    case 'responsive_caregiving':
      return 'bg-[#fcc597]';
    case 'classroom_organization':
    case 'engaged_support_for_learning':
      return 'bg-[#ffa9c6]';
    case 'instructional_support':
      return 'bg-[#f0b2ff]';
    default:
      return 'bg-[#f4f8fa]';
  }
}

type Params = {
  ageLevel: string;
  nodeIds: number[];
  endDate: OptionalDate;
  startDate: OptionalDate;
  assessmentTemplateId: number;
};

export function formatParams({
  ageLevel,
  nodeIds,
  startDate,
  endDate,
  assessmentTemplateId
}: Params) {
  return {
    age_level: ageLevel,
    node_ids: nodeIds,
    start_date: startDate?.toISOString(),
    end_date: endDate?.toISOString(),
    assessment_template_id: assessmentTemplateId
  };
}

interface UseGetReportAveragesProps {
  target: 'domain' | 'dimension';
  isFetchEnabled: boolean;
  reportConfigurationId?: number;
}

export function useGetReportAverages({
  target,
  isFetchEnabled,
  reportConfigurationId
}: UseGetReportAveragesProps) {
  const { run, ...req } = useAsync<GetAveragesRes>();

  useEffect(() => {
    if (!isFetchEnabled) {
      return;
    }

    const params = { report_configuration_id: reportConfigurationId, target };

    run(getAverages(params));
  }, [target, run, isFetchEnabled]);

  return req;
}

export function useGetReportAveragesByNode({
  target,
  isFetchEnabled,
  reportConfigurationId
}: UseGetReportAveragesProps) {
  const { run, ...req } = useAsync<GetAveragesByNodeRes>();

  useEffect(() => {
    if (!isFetchEnabled) {
      return;
    }

    const params = { report_configuration_id: reportConfigurationId, target };

    run(getAveragesByNode(params));
  }, [target, run, isFetchEnabled]);

  return req;
}

export function useGetReportAveragesByClassroom({
  isFetchEnabled,
  reportConfigurationId
}: UseGetReportAveragesProps) {
  const { run, ...req } = useAsync<GetAveragesByClassroomRes>();
  useEffect(() => {
    if (!isFetchEnabled) {
      return;
    }

    const params = { report_configuration_id: reportConfigurationId };

    run(getAveragesByClassroom(params));
  }, [run, isFetchEnabled, reportConfigurationId]);

  return req;
}

type Node = {
  id: number;
  name: string;
  type: string;
  deleted: boolean;
  children: Node[];
};

export type Account = {
  hierarchy: Node[];
};

export type HierarchyData = {
  label: string;
  value: number;
  children: HierarchyData[];
};

export function buildHierarchy(
  nodes: Node[],
  initialChecked: number[] = []
): HierarchyData[] {
  return nodes.flat().map(node => ({
    checked: initialChecked.includes(node.id),
    label: `${node.name} ${node.deleted ? `(${i18n.ft(messages.archived)})` : ''}`,
    value: node.id,
    children: buildHierarchy(node.children, initialChecked)
  }));
}

export function populateAgeLevelForAccount(
  templatesAvailable: AssessmentTemplate[]
): DropdownItemProps[] {
  const possibleAgeLevels = classAgeLevels.concat(class2EditionAgeLevels);
  const classVersions = templatesAvailable.map(
    template => template.class_version
  );
  return possibleAgeLevels.filter(ageLevel =>
    classVersions.includes(ageLevel.class_version)
  );
}

export function getTemplateIdByAgeLevelAndClassVersion(
  ageLevelOption: DropdownItemProps | undefined,
  templatesAvailable: AssessmentTemplate[]
): number {
  if (!ageLevelOption) {
    return 0;
  }

  const template = templatesAvailable.find(
    template => template.class_version === ageLevelOption.class_version
  );
  return template ? template.id : 0;
}

/**
 * Returns the last report configuration.
 * Workaround until we have a way to pick a report configuration from the UI.
 */
export function useLastReportConfiguration(accountId: number) {
  const { run, data } = useAsync<UserReportConfiguration[]>();

  useEffect(() => {
    run(getReportConfigurations());
  }, [run, accountId]);

  return (data && data.length && R.last(data)) || null;
}
