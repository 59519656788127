import { useNavigate } from 'react-router-dom';
import RoutesHelper from 'helpers/routes';
import messages from './messages';
import i18n from 'helpers/i18n';

interface SubscriptionProps {
  subscription: {
    id: any;
    name: string;
    product: { display_name: string; name: string };
    end_date: string;
    max_seats: number;
    users: number;
  };
}

function SubscriptionCard({ subscription }: SubscriptionProps) {
  const navigate = useNavigate();
  const isLearningResources =
    subscription.product.name === 'learning_resources';

  const handleClick = () => {
    if (isLearningResources) {
      return navigate(
        RoutesHelper.getPath('organization-subscriptions-learning-resources', {
          id: subscription.id
        })
      );
    } else {
      // TODO: implement for data collection
      return;
    }
  };

  return (
    <>
      <div
        className={`text-white p-2 rounded-t-lg ${isLearningResources ? 'bg-purple-500' : 'bg-orange-500'}`}
      ></div>
      <div
        className="p-4 rounded-b-lg shadow-md cursor-pointer min-h-60"
        onClick={handleClick}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold text-gray-800 text-[16px]">
            {subscription.product.display_name}
          </h2>
          <div
            className={`p-2 rounded-full size-10 flex justify-center items-center ${isLearningResources ? 'bg-purple-100' : 'bg-orange-100'}`}
          >
            <i
              className={`fas text-xl ${
                isLearningResources ? 'fa-graduation-cap' : 'fa-clipboard'
              }`}
            ></i>
          </div>
        </div>
        <div className="text-sm">
          <div className="mb-4 text-[20px]">
            <strong>{subscription.name}</strong>
          </div>

          <div className="mb-3 flex justify-between text-[16px]">
            <span className="font-bold">{i18n.ft(messages.expiresOn)}:</span>{' '}
            {subscription.end_date || '00/00/00'}
          </div>

          {isLearningResources ? (
            <>
              <div className="font-bold mb-1 mt-3 text-gray-800">
                {i18n.ft(messages.seats)}
              </div>
              <div className="flex flex-col justify-between">
                <div className="mb-1 flex justify-between">
                  <span className="font-bold">
                    {i18n.ft(messages.available)}:
                  </span>
                  {subscription.max_seats - subscription.users}
                </div>
                <div className="mb-1 flex justify-between">
                  <span className="font-bold">{i18n.ft(messages.used)}:</span>
                  {subscription.users}
                </div>
                <div className="mb-1 flex justify-between">
                  <span className="font-bold">{i18n.ft(messages.total)}:</span>
                  {subscription.max_seats}
                </div>
              </div>
            </>
          ) : (
            // TODO: implement for Data Collection
            <div className="mb-3 flex justify-between text-[16px]">
              <span className="font-bold">{i18n.ft(messages.classrooms)}:</span>{' '}
              1.000
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SubscriptionCard;
