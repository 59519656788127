import axios from 'axios';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE
} from 'pages/LearningResources/utils';

type Coaching = {
  coach_id: number;
  educator_id: number;
};

export function getMyEducators() {
  return axios.get('/api/v2/coachings');
}

export function getEducatorRecommendations(
  educatorGuid: string | undefined,
  perPage: number = DEFAULT_PER_PAGE,
  currentPage: number = DEFAULT_CURRENT_PAGE
) {
  return axios.get(
    `/api/v2/coachings/recommendations_by_educator?educator_guid=${educatorGuid}&per_page=${perPage}&page=${currentPage}`
  );
}

export function createCoachings(subscriptionId: number, coachings: Coaching[]) {
  return axios.post(`/api/v2/coachings`, {
    subscription_id: subscriptionId,
    coachings: [...coachings]
  });
}
